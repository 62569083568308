// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

p {
    font-weight: normal;
    font-size: $font-size-22;
    color: $color-black;
    margin: 0;
    line-height: 1.5;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li .submenu ul .subitem.active > a > div {
    color: $color-white;
}

/** Replace the width of the container **/
.container {
    width: 1550px;
    margin: auto;
    @media screen and (max-width: 1550px) {
        width: 90%;
        padding: 0 25px;
        @media screen and (max-width: $size-md-max) {
            width: 95%;
        }
        @media screen and (max-width: $size-sm-max) {
            padding: 0;
        }
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-bursts {
    background: #232323;
    padding: 5px 25px;
    a.burst {
        background: url("../images/accueil_section01_burst_bg.png");
        min-height: 145px;
        height: 150px;
        width: 180px;
        background-size: 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        h3 {
            font-weight: 600;
            font-size: $font-size-20;
            color: $color-black;
            span {
                font-weight: 900;
            }
        }
        img.img-responsive.hover {
            display: none;
        }
        &:hover {
            background: url("../images/accueil_section01_burst_bg_hover.png");
            background-repeat: no-repeat;
            h3 {
                color: #e00d21;
            }
            img.img-responsive.hover {
                display: block;
            }
            img.img-responsive.defaut {
                display: none;
            }
        }
    }
}

#section-text-img {
    padding: 75px 0 100px 0;
    .text-box {
        h3 {
            font-weight: 600;
            font-size: $font-size-36;
            color: #e00d21;
            text-transform: initial;
            padding-bottom: 25px;
            line-height: 1.2;
            span {
                font-weight: 900;
            }
        }
    }
    .left-side {
        width: 50%;
        margin-right: 10%;
        .text-box {
            h3 {
                font-weight: 600;
                font-size: $font-size-36;
                color: #e00d21;
                text-transform: initial;
                padding-bottom: 25px;
                line-height: 1.2;
                span {
                    font-weight: 900;
                }
            }
            h4 {
                font-weight: 600;
                font-size: $font-size-36;
                color: $color-black;
                padding-bottom: 25px;
                line-height: 1.1;
                span {
                    font-weight: 900;
                }
            }
        }
    }
    .right-side {
        width: 50%;
        .section-images {
            display: flex;
            flex-wrap: wrap;
            .image {
                width: 47.5%;
                padding: 10px 0;
            }
            .image.second {
                padding: 10px 0 0 0;
            }
        }
    }
}

#section-parallax {
    background: url("../images/section03_parallax_bg.jpg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15%;
    h3 {
        font-weight: 900;
        font-size: $font-size-46;
        color: $color-white;
    }
    h4 {
        font-weight: 600;
        font-size: $font-size-36;
        color: #e00d21;
        line-height: 1.2;
    }
}

#section-logo-text {
    padding: 100px 0;
    .section-logo {
        h4 {
            font-weight: 600;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 25px;
        }
        .main-content {
            position: relative;
            .col-xs-12.txt {
                padding-left: 0;
            }
            .owl-theme {
                .custom-nav {
                    position: absolute;
                    top: 20%;
                    left: 0;
                    right: 0;

                    .owl-prev, .owl-next {
                        position: absolute;
                        height: 100px;
                        color: inherit;
                        background: none;
                        border: none;
                        z-index: 100;

                        i {
                            font-size: 2.5rem;
                            color: #be1e2d;
                        }
                    }
                }
            }
            .arrow.prev.next-vedette {
                position: absolute;
                left: -70px;
                top: 40%;
                z-index: 1;
            }
            .arrow.next.prev-vedette {
                position: absolute;
                right: -65px;
                top: 40%;
                z-index: 1;
            }
            .arrow {
                .img-responsive.hover {
                    display: none;
                }
                &:hover {
                    .img-responsive.hover {
                        display: block;
                    }
                    .img-responsive.defaut {
                        display: none;
                    }
                }
            }
        }
    }
    .section-text-burst.flex {
        padding-top: 100px;
        .left-side {
            h4 {
                font-weight: 600;
                font-size: $font-size-30;
                color: $color-black;
                padding-bottom: 25px;
            }
            ul {
                list-style-image: url(../images/accueil_section04_crochet.jpg);
                padding-left: 20%;
                li {
                    font-weight: bold;
                    font-size: $font-size-22;
                    text-transform: initial;
                    color: $color-black;
                    line-height: 1.5;
                    padding-bottom: 5px;
                    padding-left: 15px;
                }
            }
            .solo-logo img.img-responsive.defaut {
                margin: auto;
            }
        }
        .right-side {
            a.burst {
                background: url("../images/accueil_section04_burst_bg.jpg");
                height: 145px;
                width: 734px;
                background-size: 100%;
                background-repeat: no-repeat;
                justify-content: center;
                .text {
                    width: 50%;
                    h3 {
                        font-weight: 600;
                        font-size: $font-size-28;
                        color: $color-black;
                        span {
                            font-weight: 900;
                        }
                    }
                }
                img.img-responsive.hover {
                    display: none;
                }
                &:hover {
                    background: url("../images/accueil_section04_burst_bg_hover.jpg");
                    background-repeat: no-repeat;
                    h3 {
                        color: #e00d21;
                    }
                    img.img-responsive.hover {
                        display: block;
                    }
                    img.img-responsive.defaut {
                        display: none;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* MÉCANIQUE
*******************************************************************************/

#section-text-img.mecanique {
    .left-side {
        width: 50%;
        margin-right: 5%;
        span.small {
            font-size: $font-size-22;
            font-weight: 700;
            color: #e00d21;
        }
    }
    .right-side {
        h3 {
            font-weight: 600;
            font-size: $font-size-30;
            color: #e00d21;
            text-transform: initial;
            line-height: 1.2;
            padding-top: 5px;
        }
        ul {
            list-style-image: url(../images/accueil_section04_crochet.jpg);
            padding-left: 5%;
            padding-top: 35px;
            li {
                font-weight: bold;
                font-size: $font-size-22;
                text-transform: initial;
                color: $color-black;
                line-height: 1.5;
                padding-bottom: 25px;
                padding-left: 20px;
            }
        }
    }
} 

#section-text-logo {
    .section-text {
        background: #000;
        padding: 50px 10% 75px 10%;
        border-radius: 15px;
        margin-bottom: 50px;
        h4 {
            font-weight: bold;
            font-size: $font-size-36;
            text-transform: uppercase;
            padding-bottom: 35px;
            color: $color-white;
        }
        h5 {
            font-weight: bold;
            font-size: $font-size-26;
            color: $color-white;
            padding-bottom: 15px;
        }
    }
    .section-logo {
        margin-bottom: 35px;
        .logo {
            width: 15%;
            padding: 0 10px;
            .img-responsive {
                margin: auto;
            }
        }
    }
}

#section-logo-text.mecanique {
    padding: 50px 0;
    .section-text-burst {
        padding-top: 25px;
        .left-side {
            width: 50%;
            margin-right: 10%;
            .text-box {
                h3 {
                    font-weight: 600;
                    font-size: $font-size-36;
                    color: #e00d21;
                    text-transform: initial;
                    padding-bottom: 25px;
                    line-height: 1.2;
                    span {
                        font-weight: 900;
                    }
                }
                h4 {
                    font-weight: 600;
                    font-size: $font-size-36;
                    color: $color-black;
                    padding-bottom: 25px;
                    line-height: 1.1;
                    span {
                        font-weight: 900;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* CARROSSERIE
*******************************************************************************/

#section-text-img.carrosserie {
    padding: 75px 0 0 0;
    .left-side {
        width: 50%;
        margin-right: 5%;
        h4 {
            font-weight: bold;
            font-size: $font-size-26;
            color: #d41b35;
            line-height: 1.5;
        }
        h5 {
            font-weight: 800;
            font-size: $font-size-26;
            color: $color-black;
            line-height: 1.5;
            span {
                text-transform: uppercase;
                color: #f50c0c;
            }
        }
        ul {
            list-style-image: url(../images/accueil_section04_crochet.jpg);
            padding-left: 15%;
            padding-top: 65px;
            li {
                font-weight: bold;
                font-size: $font-size-22;
                text-transform: initial;
                color: $color-black;
                line-height: 1.5;
                padding-bottom: 15px;
                padding-left: 20px;
            }
        }
    }
    .right-side {
        .icon  {
            img.img-responsive.hidden-xs {
                margin: auto;
                padding: 30px 0 40px 0;
            }
        }
        h3 {
            text-align: center;
            font-weight: 900;
            font-size: $font-size-36;
            color: #f50c0c;
            padding-bottom: 25px;
        }
        h4 {
            text-align: center;
            font-weight: 600;
            font-size: $font-size-32;
            color: #f50c0c;
            padding-bottom: 15px;
        }
    } 
}

/*******************************************************************************
* HYDRAULIQUE
*******************************************************************************/

#section-text-img.carrosserie.hydraulique {
    padding: 75px 0;
    ul {
        padding-left: 4%;
        padding-top: 20px;
    }
} 

/*******************************************************************************
* ACCESSOIRES
*******************************************************************************/

#section-text-img.mecanique.carrosserie.accessoires {
    padding: 75px 0;
    .left-side {
        a.burst {
            background: url(../images/accueil_section04_burst_bg.jpg);
            height: 145px;
            width: 734px;
            background-size: 100%;
            background-repeat: no-repeat;
            justify-content: center;
            .text {
                width: 50%;
                h3 {
                    font-weight: 600;
                    font-size: $font-size-28;
                    color: $color-black;
                    text-transform: uppercase;
                    padding-bottom: 0;
                    line-height: 28px;
                    span {
                        font-weight: 900;
                    }
                }
            }
            img.img-responsive.hover {
                display: none;
            }
            &:hover {
                background: url("../images/accueil_section04_burst_bg_hover.jpg");
                background-repeat: no-repeat;
                h3 {
                    color: #e00d21;
                }
                img.img-responsive.hover {
                    display: block;
                }
                img.img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
    .right-side {
        ul {
            padding-left: 10%;
            padding-top: 75px;
        }
    } 
}

#section-logo.accessoires {
    border-top: 100px solid #000;
    padding: 55px 0 0 0;
    h3 {
        text-align: center;
        font-weight: 600;
        font-size: $font-size-30;
        color: #e00d21;
        text-transform: initial;
        line-height: 1.2;
        padding-bottom: 35px;
    }
    .logo {
        width: 25%;
        padding: 15px;
        .img-responsive {
            margin: auto;
        }
    }
}

#section-logo-text.mecanique.accessoires {
    .right-side {
        .image {
            img.img-responsive {
                margin: auto;
            }
        }
    }
}

/*******************************************************************************
* HABITACLE/TOITURE
*******************************************************************************/

#section-text-img.mecanique.habitacle {
    .section-images {
        padding: 75px 0 35px 0;
        .images {
            width: 50%;
            padding: 5px;
        }
    }
}

#section-logo-text {
    .left-side.habitacle {
        margin-right: 5% !important;
        .logo {
            width: 33%;
        }
    }
}

/*******************************************************************************
* INSPECTION
*******************************************************************************/

#section-parallax.inspection {
    h4 {
        font-weight: 800;
        font-size: $font-size-36;
        color: $color-white;
        text-align: center;
        span {
            color: #bcc1c2;
        }
    }
}

#section-logo-text.mecanique.inspection {
    .left-side {
        margin-right: 5%;
    }
}

/*******************************************************************************
* CATALOGUE
*******************************************************************************/

#section-catalogue {
    .section-text-link {
        padding-top: 50px;
        h4 {
            font-weight: normal;
            font-size: $font-size-32;
            color: #e00d21;
            line-height: 1.3;
            span {
                font-weight: 800;
                text-transform: uppercase;
            }
        }
    }
    .text-tel {
        padding: 50px 0;
        .text {
            padding-right: 10%;
            h3 {
                font-weight: normal;
                font-size: $font-size-32;
                color: $color-black;
                line-height: 1.3;
                text-transform: initial;
            }
        }
        a.icon-tel {
            .icon {
                .img-responsive.hover {
                    display: none;
                }
            }
            h5 {
                font-weight: bold;
                font-size: $font-size-32;
                color: #636766;
            }
            &:hover {
                .img-responsive.hover {
                    display: block;
                }
                .img-responsive.defaut {
                    display: none;
                }
                h5 {
                    color: #e00d21 !important;
                }
            }
        }
    }
    .link {
        a.burst.flex.items-center {
            background: url(../images/accueil_section04_burst_bg.jpg);
            height: 145px;
            width: 734px;
            background-size: 100%;
            background-repeat: no-repeat;
            padding-left: 5%;
            .order {
                padding-right: 50px;
                h2 {
                    font-weight: 900;
                    font-size: $font-size-70;
                    color: $color-black;
                }
            } 
            .text {
                width: 50%;
                h3 {
                    font-weight: 900;
                    font-size: $font-size-34;
                    color: $color-black;
                    text-transform: initial;
                    padding-bottom: 0;
                    line-height: 28px;
                }
            }
            img.img-responsive.hover {
                display: none;
            }
            &:hover {
                background: url("../images/accueil_section04_burst_bg_hover.jpg");
                background-repeat: no-repeat;
                h3, h2 {
                    color: #e00d21;
                }
                img.img-responsive.hover {
                    display: block;
                }
                img.img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
}

#section-logo-text.mecanique.accessoires.catalogue {
    .left-side {
        margin-right: 6%;
    }
}

/*******************************************************************************
* PHOTOS/VIDÉOS
*******************************************************************************/

#section-photos-videos {
    padding: 75px 0;
    ul.menu-filter {
        list-style: none;
        justify-content: center;
        li.filter-item button {
            border: none;
            background: #fff;
            padding: 0 2px;
            font-weight: 900;
            font-size: $font-size-30;
            color: #e00d21;
            cursor: pointer;
            text-transform: uppercase;
            transition: 0.3s;
            &:hover {
                color: $color-black;
                transition: 0.3s;
            }
        }
    }
    .item {
        width: 30%;
        margin: 25px;
        box-shadow: 5px 10px 25px #d2d3d2;
        border-left: 1px solid #d2d3d2;
        border-top: 1px solid #d2d3d2;
        border-bottom: 15px solid #d41b35;
        border-right: 1px solid #d2d3d2;
        padding: 50px;
        img.img-responsive {
            margin: auto;
        }
    }
    .video {
        width: 30%;
        margin: 25px;
        box-shadow: 5px 10px 25px #d2d3d2;
        border-left: 1px solid #d2d3d2;
        border-top: 1px solid #d2d3d2;
        border-bottom: 15px solid #d41b35;
        border-right: 1px solid #d2d3d2;
        padding: 50px;
        
        iframe {
            width: 100%;
            height: calc(100vw * 622 / 1550 / 3);
        }
    }
}

/*******************************************************************************
* À VENDRE
*******************************************************************************/

#section-vendre {
    padding: 75px 0;
    .item {
        width: 30%;
        margin: 50px 25px;
        box-shadow: 5px 10px 25px #d2d3d2;
        border: 1px solid #d2d3d2;
        padding: 50px;
        h3 {
            font-weight: 900;
            font-size: $font-size-26;
            color: $color-black;
            padding-bottom: 5px;
        }
        p {
            font-weight: 600;
            font-size: $font-size-20;
            line-height: 1.5;
            color: $color-black;
        }
        h5 {
            font-weight: 900;
            font-size: $font-size-26;
            color: #d41b35;
            text-align: right;
            padding: 5px 0;
        }
        .image {
            position: relative;
            margin-bottom: 25px;
            .img-responsive {
                width: 100%;
            }
            .img-fleche {
                position: absolute;
                bottom: 15px;
                right: 5%;
                opacity: 0;
                transition: 0.5s;
            }   
            .overlay {
                background-color: rgba(0, 0, 0, 0.6);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                transition: all 0.3s ease-out;
            } 
            &:hover {
                .overlay {
                    opacity: 1;
                }
                .img-fleche {
                    opacity: 1;
                    transition: 0.5s; 
                }
            } 
        }
        a.button {
            display: block;
            padding-top: 25px;
            .img-responsive {
                margin: auto;
            }
            .img-responsive.hover {
                display: none;
            }
            &:hover {
                .img-responsive.hover {
                    display: block;
                }
                .img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
}

/*******************************************************************************
* DEMANDE DE SERVICES
*******************************************************************************/

#section-demande {
    padding: 50px 5% 50px 0;
    background: #e4e6e8;
    .left-side {
        padding-left: 0;
        .section-text {
            background: #fff;
            padding: 25px 25px 50px 25px;
            width: 71%;
            margin-bottom: 30px;
            margin-left: 29%;
            h4 {
                font-weight: 900;
                font-size: $font-size-24;
                color: #d41b35;
                padding-bottom: 35px;
            }
            h5 {
                font-weight: 600;
                font-size: $font-size-24;
                color: $color-black;
            }
        }
    }
    .right-side {
        h5 {
            font-weight: bold;
            font-size: $font-size-26;
            padding-bottom: 10px;
            text-transform: uppercase;
        }
        .section-form {
            background: #fff;
            padding: 25px 35px 25px 25px;
            form .buttons {
                justify-content: center;
            }
            label.title-list, .form-group.message label.control-label {
                font-weight: 900;
                font-size: $font-size-22;
                color: $color-black;
            }
            label.filedoc {
                border: 1px solid #000;
                width: 100%;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 900;
                font-size: $font-size-20;
                color: $color-black;
                padding-bottom: 0;
                margin-bottom: 0;
                padding-top: 3px;
            }
            .list-checkBox {
                label.container {
                    padding-left: 0;
                    font-weight: 600;
                    font-size: $font-size-20;
                    color: #d41b35;
                    margin-bottom: 15px;
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    text-transform: inherit;
                    transition: 0.3s;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                }
                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border: 1px solid #d41b35;
                    border-radius: 0;
                    transition: 0.3s;
                }

                /* On mouse-over, add a grey background color */
                .checkmark:hover input ~ .checkmark {
                  background-color: #b8031b;
                  transition: 0.3s;
                }

                /* When the checkbox is checked, add a blue background */
                .container input:checked ~ .checkmark {
                  background-color: #b8031b;
                  border: none;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                  content: "";
                  position: absolute;
                  display: none;
                }

                /* Show the checkmark when checked */
                .container input:checked ~ .checkmark:after {
                  display: block;
                }
                .container .checkmark:after {
                  left: 8px;
                  top: 4px;
                  width: 5px;
                  height: 10px;
                  border: solid white;
                  border-width: 0 3px 3px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
           
                .col-xs-12.col-sm-12.form-group.list-checkBox {
                    padding: 10px 0 0 0;
                    margin: 0;
                }
            } 
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-intro {
    padding: 50px 0;
    h3 {
        font-weight: 600;
        font-size: $font-size-34;
        color: $color-black;
        text-transform: initial;
        padding-bottom: 25px;
        span {
            font-weight: 900;
        }
    }
}

#section-coordonnees {
    padding: 50px 0;
    background: #e4e6e8;
    .container {
        background: #f1f2f2;
        padding: 50px 5% 50px 2%;
        .left-side {
            width: 50%;
            margin-right: 50px;
            h3 {
                font-weight: 900;
                font-size: $font-size-34;
                color: $color-black;
                padding-bottom: 20px;
            }
            h4 {
                font-weight: normal;
                font-size: $font-size-34;
                color: $color-black;
                line-height: 0.8;
                padding-bottom: 20px;
            }
            a {
                font-weight: bold;
                font-size: $font-size-34;
                color: $color-black;
            }
            .horaire {
                padding-top: 75px;
                h4 {
                    font-weight: 900;
                    font-size: $font-size-28;
                    text-transform: uppercase;
                    padding-bottom: 10px;
                }
                .jours-heures {
                    padding-top: 10px;
                    display: flex;
                    align-items: center;
                    h5 {
                        font-weight: bold;
                        font-size: $font-size-26;
                        padding-bottom: 5px;
                        text-transform: uppercase;
                    }
                    p {
                        line-height: 28.6px;
                        padding-bottom: 5px;
                    }
                    .jour {
                        width: 30%;
                        padding-right: 15px;
                    }
                }
            }
            .section-form {
                padding-top: 75px;
                h5 {
                    font-weight: bold;
                    font-size: $font-size-26;
                    padding-bottom: 20px;
                    text-transform: uppercase;
                }
                form .buttons {
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .right-side {
            width: 50%;
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1725px) {
   
.slideshow-wrap .caption {
    top: 45%;
    left: 18%;
}
#section-parallax {
    padding: 0 5%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.9rem;
}
}

@media screen and (max-width: 1680px) {

#section-logo-text .section-logo .main-content .arrow.prev.next-vedette, #section-logo-text .section-logo .main-content .arrow.next.prev-vedette {
    display: none;
}
nav.module-menu .menu-navbar .wrapper.right .tel-facebook {
    display: none;
}
nav.module-menu .menu-toggle {
    position: absolute;
    right: 0;
}
}

@media screen and (max-width: 1550px) {

#section-text-img {
    padding: 75px 3% 100px 3%;
}
#section-logo-text {
    padding: 100px 3%;
}
#section-text-img .right-side .section-images .image {
    width: 50%;
}
#section-logo-text.mecanique .section-text-burst {
    align-items: center;
}
#section-catalogue .link a.burst {
    width: 615px !important;
    padding-bottom: 20px;
}
#section-catalogue .link a.burst.flex.items-center .icon {
    width: 15%;
}
#section-vendre .item {
    width: 29%;
}
#section-photos-videos .video, #section-photos-videos .item {
    width: 40%;
}
#section-photos-videos .video iframe {
    height: calc(100vw * 947 / 1550 / 3);
}
#section-catalogue .link a.burst.flex.items-center:hover {
    background-size: contain;
}
}

@media screen and (max-width: 1490px) {
   
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title {
    font-size: 0.9rem;
}
.slideshow-wrap .caption {
    top: 45%;
    left: 21%;
}
#section-logo-text .left-side.habitacle .logo {
    width: 48%;
}
#section-vendre .item {
    width: 46%;
}
#section-text-img .text-box h3 br {
    display: none;
}
}

@media screen and (max-width: 1444px) {

#section-vendre .item {
    width: 45%;
}
}

@media screen and (max-width: 1430px) {

#section-text-img.mecanique.carrosserie.accessoires .left-side a.burst {
    height: 130px;
    width: 645px;
}
#section-text-img.mecanique.carrosserie.accessoires .left-side a.burst .icon {
    width: 12%;
}
#section-catalogue .link a.burst {
    width: 550px !important;
    padding-bottom: 30px;
}
#section-text-img.mecanique.carrosserie.accessoires .left-side a.burst:hover {
    background-size: contain;
}
}

@media screen and (max-width: 1410px) {
   
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 200px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title {
    font-size: 0.85rem;
}
}

@media screen and (max-width: 1330px) {
   
nav.module-menu .menu-navbar .wrapper.right .tel-facebook .icon img {
    width: 60%;
}
nav.module-menu .menu-navbar .wrapper.right .tel-facebook {
    padding-right: 0;
    height: 20px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title {
    font-size: 0.8rem;
}
}


@media screen and (max-width: $size-md-max) {
   
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title {
    font-size: 0.9rem;
}
nav.module-menu .menu-navbar .wrapper.right .tel-facebook .icon img {
    width: initial;
}
nav.module-menu .menu-navbar .wrapper.right .tel-facebook {
    height: 60px;
}
#section-text-img .right-side .section-video iframe {
    width: 532px !important;
    height: 300px !important;
}
#section-text-img.mecanique .right-side ul li {
    padding-bottom: 15px;
}
.module-header--caption {
    top: 45%;
    left: 3%;
}
#section-logo-text.mecanique .section-text-burst, #section-demande {
    flex-direction: column;
}
#section-logo-text.mecanique .section-text-burst .left-side {
    width: 65%;
    margin: 0 auto 35px auto;
}
#section-logo-text .left-side.habitacle {
    margin: 0 0 35px 0 !important;
    width: 100% !important;
}
#section-logo-text .left-side.habitacle .logo {
    width: 23%;
    padding: 10px;
}
#section-logo-text.mecanique .section-text-burst.habitacle {
    padding-top: 0;
}
#section-logo-text.mecanique.habitacle {
    padding: 0 0 50px 0;
}
#section-coordonnees .container .left-side .horaire .jours-heures p {
    line-height: 25.6px;
}
#section-logo-text.mecanique.accessoires.catalogue .left-side {
    margin: 0 auto 35px auto;
}
#section-demande .left-side .section-text {
    width: 53%;
    margin: 0 auto 30px auto;
}
#section-demande {
    padding: 50px 5%;
}
#section-demande .left-side.col-xs-12.col-sm-4, .right-side.col-xs-12.col-sm-8 {
    width: 100%;
}
#section-demande .left-side.col-xs-12.col-sm-4 {
    margin-bottom: 50px;
}
#section-demande .left-side.col-xs-12.col-sm-4 .image img.img-responsive {
    margin: auto;
}
#section-demande .right-side .section-form .form-group.pt3 {
    padding-top: 22px;
}
}

@media screen and (max-width: 1024px) {

.slideshow-wrap .caption {
    top: 45%;
    left: 27%;
}
#section-text-img .container, .section-text-burst {
    flex-direction: column;
}
#section-logo-text .section-text-burst.flex .left-side ul {
    padding-left: 30px;
}
.section-text-burst .right-side {
    margin: auto;
    padding-top: 25px;
}
#section-text-img .left-side {
    width: 100%;
    margin-right: 0;
    margin-bottom: 35px;
}
#section-text-img .right-side {
    width: 100%;
}
#section-text-img .right-side .section-images .image .img-responsive, #section-text-img.mecanique.carrosserie.accessoires .left-side .image img.img-responsive {
    margin: auto;
}
#section-text-img .right-side .section-video {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}
.module-header--caption {
    top: 55%;
    left: 3%;
}
#section-text-img.mecanique .left-side {
    width: 100%;
    margin: 0;
}
#section-text-img.mecanique .left-side .text-box h3 br {
    display: none;
}
#section-text-img.mecanique .left-side .img .img-responsive, #section-text-img.mecanique .right-side .image .img-responsive, #section-logo-text.mecanique .section-text-burst .left-side .img .img-responsive {
    margin: auto;
}
#section-text-img.mecanique .right-side {
    margin-top: 35px;
}
#section-text-img.mecanique .right-side ul {
    padding-left: 13%;
}
#section-text-logo .section-text {
    padding: 50px 5% 75px 5%;
}
#section-text-logo .section-logo .logo {
    width: 11%;
}
#section-logo-text.mecanique .section-text-burst .left-side {
    width: 70%;
}
#section-text-img .right-side .image img.img-responsive, #section-text-img.carrosserie .left-side .img img.img-responsive, #section-text-img.mecanique.carrosserie.accessoires .left-side a.burst {
    margin: auto;
}
#section-text-img.carrosserie .left-side {
    width: 100%;
    margin: 0;
}
#section-text-img.carrosserie.hydraulique .left-side {
    margin: 0 0 35px 0;
}
#section-parallax.inspection h4 br {
    display: none;
}
#section-text-img.inspection {
    padding-bottom: 50px;
}
#section-text-logo .section-logo .logo {
    width: 20%;
}
#section-logo-text .left-side.habitacle .logo {
    width: 22%;
}
#section-intro p br {
    display: none;
}
#section-coordonnees .container .left-side .horaire .jours-heures .jour {
    width: 43%;
}
#section-catalogue .text-tel, #section-catalogue .section-text-link .link {
    flex-direction: column;
}
#section-catalogue .text-tel .text {
    padding-right: 0;
    padding-bottom: 25px;
}
#section-catalogue .link a.burst {
    width: 635px !important;
    padding-bottom: 15px;
    margin: auto;
}
#section-demande .left-side .section-text {
    width: 65%;
}
#section-demande .right-side .section-form .form-group.pt3 {
    padding-top: 5px;
}
#section-vendre .item {
    width: 44%;
}
#section-photos-videos .video iframe {
    height: calc(100vw * 892 / 1550 / 3);
}
}

@media screen and (max-width: $size-sm-max) {

.slideshow-wrap .caption {
    top: 45%;
    left: 32%;
}
#section-parallax h4 br {
    display: none;
}
.module-header--caption--title {
    font-size: 2rem;
}
.module-header--caption {
    top: 83%;
    left: 3%;
}
#section-logo-text.mecanique .section-text-burst .left-side, #section-coordonnees .container .right-side {
    width: 100%;
}
#section-text-img.carrosserie .left-side ul {
    padding-left: 5%;
}
#section-logo .logo {
    width: 45%;
    padding: 15px;
}
#section-logo .logo .img-responsive {
    margin: auto;
}
.module-header--caption--title br {
    display: none;
}
#section-logo.accessoires .logo {
    width: 50%;
}
#section-coordonnees .container {
    padding: 50px 5% 50px 5%;
    flex-direction: column;
}
#section-coordonnees .container .left-side {
    width: 100%;
    margin: 0 0 50px 0;
}
#section-coordonnees .container .left-side .horaire .jours-heures .jour {
    width: 26%;
}
#section-coordonnees .container .right-side div#map-canvas {
    height: 400px !important;
}
#section-demande .left-side .section-text {
    width: 87%;
}
#section-demande .right-side .section-form .left.col-xs-12.col-sm-5.pl0, #section-demande .right-side .section-form .right.col-xs-12.col-sm-7.px0 {
    width: 100%;
}
#section-demande .right-side .section-form label.filedoc {
    position: relative;
    left: -55%;
}
#section-demande .right-side .section-form .form-group.pb5 {
    padding-bottom: 4px;
}
#section-vendre .item {
    width: 75%;
    margin: 0 auto 50px auto;
}
#section-photos-videos .video, #section-photos-videos .item {
    padding: 35px;
}
#section-photos-videos .video iframe {
    height: calc(100vw * 966 / 1550 / 3);
}
}

@media screen and (max-width: $size-xs-max) {

nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 160px;
    padding: 3px;
} 
.slideshow-wrap .caption {
    top: 67%;
    left: 54%;
    width: 100%;
}
#section-bursts {
    flex-direction: column;
}
#section-text-img .right-side .section-video iframe {
    width: 532px !important;
    height: 220px !important;
}
#section-text-img .right-side .section-images .image, #section-text-img .right-side .section-images .image.second {
    width: 100%;
    padding: 0 0 5px 0;
}
#section-parallax {
    background: url("../images/section03_parallax_bg_m.jpg");
    min-height: 450px;
}
#section-logo-text .section-text-burst.flex .right-side a.burst, #section-text-img.mecanique.carrosserie.accessoires .left-side a.burst, #section-catalogue .link a.burst {
    flex-direction: column;
    background: none !important;
    border: 3px solid #e00d21;
    border-radius: 10px;
    width: 100% !important;
    height: 170px !important;
    text-align: center;
}
#section-logo-text .section-text-burst.flex .right-side a.burst:hover {
    background: none;
    border: 3px solid #000;
}
#section-logo-text .section-text-burst.flex .right-side a.burst .text {
    width: 100%;
    padding-bottom: 10px;
}
#section-logo-text .section-text-burst.flex .right-side a.burst.my1 {
    margin: 25px 0;
}
#section-text-img.mecanique.carrosserie.accessoires .left-side a.burst .icon {
    width: 15%;
    margin-top: 5px;
}
.section-text-burst .right-side {
    padding-top: 25px;
    width: 100%;
    margin: initial;
}
.module-header--caption--title {
    font-size: 1.9rem;
}
div#section-images {
    flex-direction: column;
}
#section-text-logo .section-logo .logo {
    width: 35%;
    padding: 20px;
}
#section-text-logo .section-formation {
    flex-wrap: wrap;
}
#section-text-logo .section-formation .bloc {
    width: 45%;
}
#section-text-img.mecanique .right-side ul {
    padding-left: 8%;
}
#section-images .image:first-child {
    padding-bottom: 10px;
}
#section-text-img.carrosserie .left-side ul {
    padding-left: 9%;
}
#section-text-img.carrosserie.hydraulique .left-side .images {
    flex-direction: column;
}
#section-logo .logo {
    width: 38%;
    padding: 15px;
}
#section-logo-text .left-side.habitacle .logo {
    width: 44%;
}
#section-text-img.mecanique.habitacle .section-images .images {
    width: 100%;
    padding: 0 0 5px 0;
}
#section-coordonnees .container .left-side .horaire .jours-heures .jour {
    width: 46%;
}
#section-coordonnees .container .left-side .horaire .jours-heures p {
    line-height: 20.6px;
}
#section-catalogue .link a.burst.flex.items-center .order {
    padding-right: 0;
}
#section-catalogue .link a.burst.first {
    margin-bottom: 25px;
}
#section-catalogue .link a.burst {
    padding-left: 0 !important;
    height: 180px !important;
    padding-bottom: 0 !important;
    padding-top: 5px;
}
#section-demande .left-side .section-text {
    width: 100%;
}
#section-demande .right-side .section-form .form-group.pb5 {
    padding-bottom: 15px;
}
#section-demande .right-side .section-form label.filedoc {
    position: initial;
    width: 76%;
    margin: auto;
}
#section-vendre .item {
    width: 100%;
    padding: 50px 35px;
}
#section-photos-videos .item, #section-photos-videos .video {
    width: 100%;
}
#section-photos-videos .video iframe {
    height: calc(100vw * 737 / 1550 / 1);
}
#section-vendre .item a.button {
    width: 55%;
    margin: auto;
}
}

@media screen and (max-width: 360px) {
 
.slideshow-wrap .caption {
    top: 68%;
    left: 55%;
    width: 100%;
}
.slideshow-wrap .caption h2, .slideshow-wrap .caption h3 {
    font-size: 2.3rem;
}
.module-header--caption--title {
    font-size: 1.7rem;
}
#section-text-img.mecanique.carrosserie.accessoires .left-side a.burst .text h3 {
    font-size: 1.2rem;
}
#section-logo.accessoires .logo {
    width: 40%;
}
#section-demande .right-side .section-form label.filedoc {
    width: 92%;
}
#section-photos-videos .video iframe {
    height: calc(100vw * 688 / 1550 / 1);
}
}